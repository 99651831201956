<template>
  <v-container fluid>
    <div class="d-flex justify-space-between mb-5">
      <div class="d-flex align-baseline">
        <h1 class="text-h1">配分管理</h1>
        <v-btn
          text
          @click="isExpand = !isExpand"
          class="ml-2"
          :color="hasFilterData ? 'fubonGreen' : ''"
          ><v-icon>mdi-filter-variant</v-icon><span class="ml-1">篩選</span>
        </v-btn>
      </div>
      <v-btn
        v-if="rolesCheck('allocationAdd')"
        @click="openAddDialog"
        color="primary"
        >新增版本</v-btn
      >
    </div>
    <material-filter
      @filter="filterChoose"
      :isExpand="isExpand"
    ></material-filter>

    <material-table
      :headers="header"
      :items="item_list"
      :options.sync="pageMeta.options"
    >
      <template #no="{ item }">
        {{ versionText(item.no) }}
      </template>
      <template #status="{ item }">
        <chip-status small :status="item.status"> </chip-status>
      </template>
      <template #updatedAt="{ item }">
        {{ dateFormate(item.updatedAt) }}
      </template>
      <template #versioningAt="{ item }">
        {{ dateFormate(item.versioningAt) }}
      </template>
      <template #createdAt="{ item }">
        {{ dateFormate(item.createdAt) }}
      </template>
      <template #actions="{ item }">
        <div class="text-no-wrap">
          <v-icon @click="toPage(item)">mdi-eye</v-icon>
          <v-icon
            v-if="rolesCheck('allocationDelete')"
            @click="openDeleteDialog(item)"
            class="ml-2"
            :disabled="item.status != 0 && item.status != 1"
            >mdi-delete</v-icon
          >
        </div>
      </template>
    </material-table>
    <material-pagination
      :totalPages="pageMeta.totalPages"
      @pageFilter="pageFilter"
    ></material-pagination>
    <!-- 新增配分版本 -->
    <material-dialog v-model="addDialog" title="新增配分版本" width="350">
      <material-select
        v-model="newVersionData.copyFrom"
        prependTitle="配分版本"
        label="引用版本號"
        :items="canCopyVersionList"
        item-text="text"
        item-value="id"
      >
      </material-select>
      <div class="mt-5">
        新增配分版本號
        <chip-grey
          >{{ newVersionData.year }}-{{
            versionText(newVersionData.no)
          }}</chip-grey
        >
      </div>
      <template #actions>
        <v-spacer></v-spacer>
        <btn-cancel @click="addDialog = false">取消</btn-cancel>
        <v-btn @click="createAllocation" depressed color="primary">新增</v-btn>
      </template>
    </material-dialog>
    <!-- 刪除配分版本 -->
    <material-dialog title="刪除配分版本" v-model="deleteAlert" max-width="400">
      您確定要刪除配分版本
      <chip-grey>
        {{ `${nowItem.year}-${versionText(nowItem.no)}` }}
      </chip-grey>
      嗎？
      <template #actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="deletAllocation" depressed>刪除版本</v-btn>
        <btn-cancel @click="deleteAlert = false">保留版本</btn-cancel>
      </template>
    </material-dialog>
  </v-container>
</template>
<script>
import AllocationSetting from "./AllocationSetting.vue";
import AllocationAdd from "./components/AllocationAdd.vue";
import {
  apiGetAllocationData,
  apiCreateAllocation,
  apiDeleteAllocation,
} from "@/api/allocation";
import {
  dateFormateMixin,
  versionMixin,
  searchMixin,
  checkRolesMixin,
} from "@/helper";
export default {
  name: "AllocationManagement",
  mixins: [dateFormateMixin, versionMixin, searchMixin, checkRolesMixin],
  components: {
    "allocation-setting": AllocationSetting,
    "allocation-add": AllocationAdd,
  },
  data() {
    return {
      isExpand: false,
      header: [
        { text: "年度", value: "year", sortable: false },
        { text: "版本", value: "no", sortable: false },
        { text: "狀態", value: "status", sortable: false },
        { text: "更新時間", value: "updatedAt" },
        { text: "定版時間", value: "versioningAt" },
        { text: "建立時間", value: "createdAt" },
        { text: "操作", value: "actions", sortable: false, width: 100 },
      ],
      item_list: [
        // {
        //   id: 1,
        //   year: "2022",
        //   no: 2,
        //   status: 0,
        //   startDay: "2022-07-01",
        //   updatedAt: "2022-06-18 14:57:49",
        //   versioningAt: "2022-06-18 14:57:49",
        //   createdAt: "2022-06-18 14:33:08",
        //   action: true,
        // },
        // {
        //   id: 2,
        //   year: "2023",
        //   no: 1,
        //   status: 1,
        //   startDay: "2022-07-01",
        //   updatedAt: "2022-06-18 14:57:49",
        //   versioningAt: "2022-06-18 14:57:49",
        //   createdAt: "2022-06-18 14:33:08",
        // },
        // {
        //   id: 3,
        //   year: "2022",
        //   no: 2,
        //   status: 2,
        //   startDay: "2022-07-01",
        //   updatedAt: "2022-06-18 14:57:49",
        //   versioningAt: "2022-06-18 14:57:49",
        //   createdAt: "2022-06-18 14:33:08",
        // },
        // {
        //   id: 4,
        //   year: "2022",
        //   no: 2,
        //   status: 3,
        //   startDay: "2022-07-01",
        //   updatedAt: "2022-06-18 14:57:49",
        //   versioningAt: "2022-06-18 14:57:49",
        //   createdAt: "2022-06-18 14:33:08",
        // },
        // {
        //   id: 5,
        //   year: "2022",
        //   no: 2,
        //   status: 4,
        //   startDay: "2022-07-01",
        //   updatedAt: "2022-06-18 14:57:49",
        //   versioningAt: "2022-06-18 14:57:49",
        //   createdAt: "2022-06-18 14:33:08",
        // },
        // {
        //   id: 6,
        //   year: "2022",
        //   no: 2,
        //   status: 0,
        //   startDay: "2022-07-01",
        //   updatedAt: "2022-06-18 14:57:49",
        //   versioningAt: "2022-06-18 14:57:49",
        //   createdAt: "2022-06-18 14:33:08",
        // },
        // {
        //   id: 7,
        //   year: "2022",
        //   no: 2,
        //   status: 0,
        //   startDay: "2022-07-01",
        //   updatedAt: "2022-06-18 14:57:49",
        //   versioningAt: "2022-06-18 14:57:49",
        //   createdAt: "2022-06-18 14:33:08",
        // },
        // {
        //   id: 8,
        //   year: "2022",
        //   no: 2,
        //   status: 0,
        //   startDay: "2022-07-01",
        //   updatedAt: "2022-06-18 14:57:49",
        //   versioningAt: "2022-06-18 14:57:49",
        //   createdAt: "2022-06-18 14:33:08",
        // },
        // {
        //   id: 9,
        //   year: "2022",
        //   no: 2,
        //   status: 0,
        //   startDay: "2022-07-01",
        //   updatedAt: "2022-06-18 14:57:49",
        //   versioningAt: "2022-06-18 14:57:49",
        //   createdAt: "2022-06-18 14:33:08",
        // },
      ],
      // === pagination ===
      pageMeta: {
        options: {
          sortBy: ["createdAt"],
          sortDesc: [true],
        },
        page: 1,
        totalPages: 1,
        itemsPerPage: 10,
      },
      filterData: null,
      // === Dialogs ===
      // add
      addDialog: false,
      rawVersionList: [],
      canCopyVersionList: [],
      newVersionData: {
        // year: "0000",
        // no: "00",
        // copyFrom: null,
      },
      // delete
      deleteAlert: false,
      nowItem: {},
      // filterCondition: "test",
    };
  },
  mounted() {
    // this.getAllocationData();
  },
  watch: {
    "pageMeta.options": {
      handler() {
        this.getAllocationData();
      },
    },
  },
  computed: {
    hasFilterData() {
      if (this.filterData) {
        let values = Object.values(this.filterData);
        let val = values.find((el) => el != null);
        console.log({ val });
        if (val === 0 || val != undefined) return true;
      }
      return false;
    },
  },
  methods: {
    async getAllocationData() {
      try {
        let searchData = this.combineQueries(this.pageMeta, this.filterData);
        console.log({ searchData });
        let res = await apiGetAllocationData(searchData);
        console.log({ res });
        // // === For test ===
        // this.item_list = [
        //   {
        //     id: 5,
        //     year: "2023",
        //     no: "2",
        //     status: 0,
        //     updatedAt: "2023-01-26 12:26:59",
        //     createdAt: "2023-01-26 12:26:59",
        //     versioningAt: "",
        //   },
        //   {
        //     id: 4,
        //     year: "2023",
        //     no: "1",
        //     status: 0,
        //     updatedAt: "2023-01-17 16:29:25",
        //     createdAt: "2023-01-17 16:29:25",
        //     versioningAt: "",
        //   },
        //   {
        //     id: 3,
        //     year: "2022",
        //     no: "2",
        //     status: 0,
        //     updatedAt: "2023-01-17 16:25:31",
        //     createdAt: "2023-01-17 16:25:04",
        //     versioningAt: "",
        //   },
        //   {
        //     id: 2,
        //     year: "2022",
        //     no: "1",
        //     status: 0,
        //     updatedAt: "2023-01-17 14:53:17",
        //     createdAt: "2023-01-14 01:47:57",
        //     versioningAt: "",
        //   },
        //   {
        //     id: 1,
        //     year: "2021",
        //     no: "1",
        //     status: 0,
        //     updatedAt: "2023-01-17 16:10:06",
        //     createdAt: "2023-01-14 01:47:08",
        //     versioningAt: "",
        //   },
        // ];
        // // === For test  end===

        this.item_list = res.data.content;
        this.pageMeta.totalPages = res.data.totalPages;
      } catch (err) {
        console.log({ err });
      }
    },
    // 新增配分版本
    openAddDialog() {
      this.getVersionList();
      this.addDialog = true;
    },
    async getVersionList() {
      try {
        let res = await apiGetAllocationData({ page: 1, size: 999 });
        console.log("getVersionList", { res });

        this.rawVersionList = res.data.content;
        this.canCopyVersionList = res.data.content.filter(
          (el) => el.status == 1
        );
        this.canCopyVersionList.forEach((el) => {
          let newNo = this.versionText(el.no);
          el.text = `${el.year}-${newNo}`;
        });
        this.newVersionData = this.getNewVersionData(this.rawVersionList);
      } catch (err) {
        console.log({ err });
      }
    },
    async createAllocation() {
      try {
        let payload = this.newVersionData;
        console.log({ payload });
        let res = await apiCreateAllocation(payload);
        console.log({ res });
        // success;
        this.addDialog = false;
        await this.getAllocationData();
      } catch (err) {
        console.log({ err });
      }
    },
    // 刪除配分版本
    async deletAllocation() {
      try {
        let res = await apiDeleteAllocation(this.nowItem.id);
        console.log({ res });
        if (res.data.message == "success") {
          this.deleteAlert = false;
          await this.getAllocationData();
        }
      } catch (err) {
        console.log({ err });
      }
    },
    // 過濾元件
    filterChoose() {
      this.filterData = arguments[0];
      console.log(this.filterData);
      this.isExpand = false;
      this.getAllocationData();
    },
    // 分頁搜尋
    pageFilter() {
      let pageParams = arguments[0];
      console.log(arguments[0]);
      this.pageMeta.page = pageParams.page;
      this.pageMeta.itemsPerPage = pageParams.itemsPerPage;
      this.getAllocationData();
    },
    edit() {},
    openDeleteDialog(item) {
      this.nowItem = item;
      this.deleteAlert = true;
    },
    itemRowBackground(item) {
      let item_idx = this.item_list.findIndex(
        (element) => element.id == item.id
      );
      console.log(item_idx % 2 != 0 ? "style-1" : "style-2");
      return item_idx % 2 != 0 ? "style-1" : "style-2";
    },
    add() {
      this.addDialog = false;
    },
    toPage(item) {
      this.$router.push("/allocation/setting/" + item.id);
    },
    // filterChoose(value) {
    //   this.filterCondition = value;
    // },
  },
};
</script>
<style scoped>
.fontStyle {
  color: grey;
}
.adjust {
  text-align: center;
}
.tableStyle {
  width: 100%;
}
.style-1 {
  background-color: white;
}
.style-2 {
  background-color: #00b1db !important;
  color: white;
}
</style>
